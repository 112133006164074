import _ from 'lodash-es';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'fullscreen-spinner',
  templateUrl: './fullscreen-spinner.component.html',
  styleUrls: [],
  standalone: true,
  imports: [NgIf],
})
export default class FullscreenSpinnerComponent implements OnInit, OnDestroy {
  onShow = false;

  constructor() { }

  ngOnInit() { }

  ngOnDestroy(): void { }

  show() {
    this.onShow = true;
  }

  hide() {
    this.onShow = false;
  }

  toggle() {
    this.onShow = !this.onShow;
  }
}


export { FullscreenSpinnerComponent };
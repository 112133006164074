<nav class="fixed top-0 z-[32] w-screen bg-white border-b border-gray-200">
  <div class="px-5 py-2 lg:px-5 lg:pl-3"
    style="background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 100%);">
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start">
        <button type="button" (click)="onToggleNav()"
          class="items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 sm:inline-flex">
          <span class="sr-only">Open sidebar</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
            </path>
          </svg>
        </button>
        <a class="flex ml-2 mr-auto">
          <img src="/assets/images/logo.png" class="mr-3 h-9" alt="Logo" />
          <span class="self-center text-xl font-semibold sm:text-xl whitespace-nowrap">CRM</span>
        </a>
      </div>
      <div class="flex items-center gap-4">
        <div class="hidden sm:block text-xs">Shortcuts</div>
        <div class="hidden sm:block" *ngIf="authHelper.verify(['0.operation.transaction:write'])">
          <a routerLink="/transactions/create" title="Create Transaction">
            <i class="fa-regular fa-hexagon-plus hover:text-rose-600 hover:scale-110"></i>
          </a>
        </div>
        <div class="hidden sm:block" *ngIf="authHelper.verify(['0.operation.entry:write'])">
          <a routerLink="/entry/game-data/create" title="Game Data Entry">
            <i class="fa-regular fa-keyboard hover:text-rose-600 hover:scale-110"></i>
          </a>
        </div>
        <div class="hidden sm:block" *ngIf="authHelper.verify(['0.operation.follow-up:write'])">
          <a routerLink="/follow" [queryParams]="{ following: 1, on_follow: 1 }" title="My Follow Up">
            <i class="fa-regular fa-headset hover:text-rose-600 hover:scale-110"></i>
          </a>
        </div>
        <div class="hidden sm:block" *ngIf="authHelper.verify(['0.operation.follow-up:write'])">
          <a routerLink="/payment/platform/accounts" title="Platform Payment Accounts">
            <i class="fa-regular fa-money-check-dollar hover:text-rose-600 hover:scale-110"></i>
          </a>
        </div>
        <div class="hidden sm:block">
          <a routerLink="/roster" title="Roster">
            <i class="fa-regular fa-calendar-week hover:text-rose-600 hover:scale-110"></i>
          </a>
        </div>
        <div class="hidden sm:block mx-1"></div>
        <div>
          <select #ddCompany [(ngModel)]="on.company_id">
            <option *ngIf="companies.length > 1" value="">All Companies ({{ companies.length }})</option>
            <option *ngFor="let company of companies" [value]="company.company_id">{{ company.name }}</option>
          </select>
        </div>
        <div class="flex items-center mr-5">
          <div>
            <button type="button" class="flex text-sm " aria-expanded="false" data-dropdown-toggle="dropdown-user">
              <span class="sr-only">Open user menu</span>
              <span>{{ username }}</span> <span class="inline-block ml-1"><i
                  class="fa-sharp fa-solid fa-caret-down"></i></span>
            </button>
          </div>
          <div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow"
            id="dropdown-user">
            <ul class="w-48 py-1" role="none">
              <li>
                <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer" role="menuitem"
                  (click)="onChangePassword()">Change Password</a>
              </li>
              <li>
                <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer" role="menuitem"
                  (click)="onChangeTimeZone()">Setting</a>
              </li>
              <li>
                <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer" role="menuitem"
                  (click)="onSignOut()">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<aside id="logo-sidebar"
  class="fixed z-[31] w-screen pt-20 top-0 left-0 transition-transform -translate-x-full bg-white border-r border-gray-200 h-full sm:w-64">
  <div class="h-full px-3 pb-4 overflow-y-auto bg-white">
    <ul>
      @for (item of menu; track i; let i = $index) {
      @if (hasSubmenu(item)) {
      <li>
        <label [for]="i" class="menu-item">
          <span class="inline-block mr-3"><i class="{{ item.icon }}"></i></span>
          <span class="capitalize">{{ item.label }}</span>
          <span class="inline-block mt-1 ml-auto"><i class="fa-light fa-angle-down"></i></span>
        </label>
        <input class="hidden submenu-toggle" type="checkbox" [id]="i">
        <ul class="submenu">
          @for (subitem of item.menu; track $index) {
          <li *authorize="subitem.requires">
            <!-- [routerLink]="subitem.path" routerLinkActive="menu-active" -->
            <a class="submenu-item" [routerLink]="subitem.path" [queryParams]="subitem.query"
              routerLinkActive="menu-active">
              <span class="capitalize">{{ subitem.label }}</span>
            </a>
          </li>
          }
        </ul>
      </li>
      } @else {
      <li *authorize="item.requires ?? []">
        <a class="menu-item" [routerLink]="item.path" routerLinkActive="menu-active">
          <span class="inline-block mr-3"><i class="{{ item.icon }}"></i></span>
          <span class="capitalize">{{ item.label }}</span>
        </a>
      </li>
      }
      }
    </ul>
  </div>
</aside>

<div class="p-2" [ngClass]="{'sm:ml-64': !drawer?.isHidden() }">
  <div class="p-2 mt-14 min-w-[800px]">
    <router-outlet (activate)="onRouterOutletActive($event)"></router-outlet>
  </div>
</div>

<message></message>
<modal></modal>
<fullscreen-spinner></fullscreen-spinner>
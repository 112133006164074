import _ from 'lodash-es';
import { throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export default class HttpService {
  baseApiPath = `//${environment.apiHost}`;
  httpOpts = { headers: { 'Content-Type': 'application/json' } }
  http: HttpClient = inject(HttpClient);

  constructor() { }

  get(path: string, params = {}, httpOpts = {}, baseurl = null) {
    const qs: string = this.buildQS(params);
    let url = !baseurl ? this.baseApiPath + path : baseurl + path;
    if (qs.length) {
      url += '?' + qs;
    }

    return (
      this.http
        .get<any>(url, {
          headers: { ...this.httpOpts.headers, ...httpOpts },
          withCredentials: true
        })
        .pipe(
          take(1),
          catchError(this.handlingError.bind(this)),
          map(response => response)
        )
    );
  }

  post(path: string, params = {}, payload = {}, httpOpts = {}, baseurl = null) {
    const qs: string = this.buildQS(params);
    let url = !baseurl ? this.baseApiPath + path : baseurl + path;
    if (qs.length) {
      url += '?' + qs;
    }

    return (
      this.http
        .post<any>(url, payload, {
          headers: { ...this.httpOpts.headers, ...httpOpts },
          withCredentials: true
        })
        .pipe(
          take(1),
          catchError(this.handlingError.bind(this)),
          map(response => response)
        )
    );
  }

  put(path: string, params = {}, payload = {}, httpOpts = {}, baseurl = null) {
    const qs: string = this.buildQS(params);
    let url = !baseurl ? this.baseApiPath + path : baseurl + path;
    if (qs.length) {
      url += '?' + qs;
    }

    return (
      this.http
        .put<any>(url, payload, {
          headers: { ...this.httpOpts.headers, ...httpOpts },
          withCredentials: true
        })
        .pipe(
          take(1),
          catchError(this.handlingError.bind(this)),
          map(response => response)
        )
    );
  }

  delete(path: string, params = {}, payload = {}, httpOpts = {}, baseurl = null) {
    const qs: string = this.buildQS(params);
    let url = !baseurl ? this.baseApiPath + path : baseurl + path;
    if (qs.length) {
      url += '?' + qs;
    }
    return (
      this.http
        .delete<any>(url, {
          headers: { ...this.httpOpts.headers, ...httpOpts },
          withCredentials: true,
          body: payload
        })
        .pipe(
          take(1),
          catchError(this.handlingError.bind(this)),
          map(response => response)
        )
    );
  }

  buildQS(params: any) {
    const arr: string[] = [];
    for (const key in params ?? []) {
      if (_.isNil(params[key])) { continue; }
      if (params[key] === '') { continue; }

      switch (true) {
        case _.isString(params[key]):
        case _.isNumber(params[key]):
          arr.push(`${key}=${params[key]}`);
          break;
        case _.isBoolean(params[key]):
          switch (params[key]) {
            case true: arr.push(`${key}=1`); break;
            case false: arr.push(`${key}=0`); break;
          }
          break;
      }
    }
    return arr.join('&');
  }

  handlingError(error: HttpErrorResponse) {
    console.log('E.httpService', error);
    return throwError(() => error)
  }
}

export { HttpService };

<div #elModal id="default-modal" data-modal-target="default-modal" tabindex="-1" aria-hidden="true"
  class="hidden fixed top-0 left-0 right-0 z-50 w-full p-4 md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative min-w-[400px] w-full sm:max-w-[80vw] sm:w-auto md:mb-0">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow">
      <!-- Modal header -->
      <div class="flex items-start justify-between px-4 py-3 border-b rounded-t bg-stone-700">
        <h3 class="text-lg font-semibold text-white">{{ title }}</h3>
        <!-- data-modal-hide="default-modal" -->
        <button type="button" (click)="close()"
          class="text-white bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="p-4 overflow-x-auto overflow-y-auto max-h-[88vh] sm:max-h-[80vh]">
        <div>
          <ng-template modal-host #vcr></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template modal-host></ng-template> -->
import _ from 'lodash-es';
import { DateTime, Settings } from 'luxon';
import { EMPTY, Observable, catchError, map, of } from 'rxjs';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { IModal } from '@components/common/modal/modal.interface';
import { MessageComponent } from '@components/common/message/message.component';
import { HttpErrorResponse } from '@angular/common/http';

import ApiService from '@services/api/main.service';

@Component({
  selector: 'app-change-timezone',
  templateUrl: './setting.component.html',
  styleUrls: [],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MessageComponent]
})
export default class SettingComponent implements OnInit, IModal {
  @ViewChild(MessageComponent) toast !: MessageComponent;


  private apiService: ApiService = inject(ApiService);

  on: any = {
    user: null
  }

  callbacks: any;
  form = new FormGroup({
    timezone: new FormControl('', [Validators.required]),
  });

  validationMessages: any = {
    "timezone.required": "Time zone is required",
  };


  constructor() { }

  ngOnInit() { }

  modalOnInit(parameters: any, callbacks: any): void {
    this.callbacks = callbacks;
    const user = localStorage.getItem('user') || '';
    this.on.user = JSON.parse(user);
    this.form.controls['timezone'].setValue(this.on.user.timezone);

  }

  onSubmit() {

    const { timezone } = this.form.value;

    if (!timezone) {
      return this.toast.show({ title: 'Change timezone failed', body: 'Please provide both current and new timezone', icon: 'error' });
    }

    const body = {
      timezone
    }
    // return this.authService.changePassword(userId, currentPassword, newPassword).subscribe({
    //   next: res => this.handleOnChangePassword(res),
    //   error: err => this.handleOnChangePasswordError(err)
    // });
    const identifiers = { id: this.on.user.user_id }
    this.apiService.call('user:update', { identifiers, body })
      .pipe(catchError(this.handleOnSubmitError.bind(this)))
      .subscribe(this.handleOnSubmit.bind(this));

  }
  errorMessage(form: FormGroup): string {
    const controls: string[] = Object.keys(form.controls);
    const messages: string[] = [];
    for (const control of controls) {
      if (!this.form.get(control)) { continue; }
      if (!this.form.get(control)?.errors) { continue; }
      const errors: ValidationErrors = this.form.get(control)!.errors!;
      for (const [type, v] of Object.entries(errors)) {
        const message = this.validationMessages[`${control}.${type}`] ?? `${control} is invalid`;
        messages.push(`- ${message}`);
      }
    }
    return messages.join("\n");
  }


  handleOnSubmit(response: any) {
    const user = this.on.user;
    user["timezone"] = this.form.value['timezone'];
    Settings.defaultZone = user["timezone"];
    localStorage.setItem('user', JSON.stringify(user));
    
    this.callbacks.onChanged();

  }

  handleOnSubmitError(response: any) {
    switch (response.status) {
      case 422:
        this.toast.show({
          title: `Server Validation Error`,
          body: this.i18n(response.error.message),
          icon: 'warning'
        });
        break;
      default:
        this.toast.show({
          title: `${response.status} ${response.statusText}`,
          body: `${response.url}\nPlease try again or contact support if the problem persists.`,
          icon: 'error'
        });
    }
    return EMPTY;
  }

  i18n(key: string): string {
    switch (key) {
      default: return key;
    }
  }
}

export { SettingComponent };

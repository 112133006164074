import { inject } from '@angular/core';
import { HttpService } from '@services/http.service';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { Role, UserRole, Permission, RolePermissions, UserPermissions, UserPermission } from '@modules/auth/types';
import { local } from 'd3';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private httpService: HttpService = inject(HttpService);
  private cookieService: CookieService = inject(CookieService);

  constructor() { }

  get isSignedIn() {
    return this.cookieService.get('expiry') !== '';
  }
  get user() {    
    try {
      const stored = localStorage.getItem('user');
      if (stored) {
        // console.log(`useruser`, JSON.parse(localStorage.getItem('user') ?? ''));
        return JSON.parse(localStorage.getItem('user') ?? '');
      }
    } catch (error) { }

    return null;
  }

  get permissions(): { role_extend: String[], role_revoke: String[], user_extend: String[], user_revoke: String[] } {
    try {
      const stored = localStorage.getItem('permissions');
      if (stored) {
        const { role_extend, role_revoke, user_extend, user_revoke } = JSON.parse(localStorage.getItem('permissions') ?? '');
        return { role_extend, role_revoke, user_extend, user_revoke };
      }
    } catch (error) { }

    return { role_extend: [], role_revoke: [], user_extend: [], user_revoke: [] };
  }

  signIn(username: string, password: string) {
    console.log(`username`, username);
    return this.httpService.post(`/auth/signin`, {}, { username, password });
  }

  register(name: string, username: string, password: string, invite_code: string) {
    return this.httpService.post(`/auth/invite`, {}, { name, username, password, invite_code });
  }

  signOut() {
    // localStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return this.httpService.post('/auth/signout');
  }

  check() {
    return this.httpService.get('/auth/check');
  }

  refreshToken() {
    return this.httpService.get('/auth/refresh');
  }

  changePassword(user_id: number, current_password: string, new_password: string) {
    return this.httpService.put('/auth/password/change', {}, { user_id, current_password, new_password });
  }

  resetPassword(user_id: number, password: string) {
    return this.httpService.put('/auth/password/reset', {}, { user_id, password });
  }

  getRoles(write: boolean = false): Observable<{ data: Role[] }> {
    return this.httpService.get(`/auth/roles?write=${write}`);
  }

  getRole(role_id: number): Observable<{ data: Role }> {
    return this.httpService.get(`/auth/roles/${role_id}`);
  }

  getPermissions(): Observable<{ data: Permission[] }> {
    return this.httpService.get('/auth/permissions');
  }

  getUserRoles(user_id: number): Observable<{ data: UserRole[] }> {
    return this.httpService.get(`/auth/users/${user_id}/roles`);
  }

  getUserPermissions(user_id: number): Observable<{ data: UserPermissions }> {
    return this.httpService.get(`/auth/users/${user_id}/permissions`);
  }

  getRolePermissions(roleId: number): Observable<{ data: RolePermissions }> {
    return this.httpService.get(`/auth/roles/${roleId}/permissions`);
  }

  updateUserRoles(user_id: number, role_ids: number[]) {
    return this.httpService.put(`/auth/users/${user_id}/roles`, {}, role_ids);
  }

  updateUserPermissions(user_id: number, user_permissions: UserPermissions) {
    return this.httpService.put(`/auth/users/${user_id}/permissions`, {}, user_permissions);
  }

  updateRolePermissions(role_id: number, role_permissions: RolePermissions) {
    return this.httpService.put(`/auth/roles/${role_id}/permissions`, {}, role_permissions);
  }
}

import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export default class MessageService {
  public message$: Subject<any> = new Subject<any>;

  constructor() { }

  show(message: any) {
    if (!message) {
      this.message$.next(null);
    } else {
      this.message$.next({
        title: message.title,
        body: message.body,
        icon: message.icon ?? null,
      })
    }
  }
}

export { MessageService };

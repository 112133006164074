export default [
  { icon: 'fa-light fa-fw fa-house', label: 'Home', path: '/home', requires: [] },
  {
    icon: 'fa-light fa-fw fa-user', label: 'Users & Permissions', menu: [
      { label: 'Users', path: '/auth/users', requires: ['0.auth.user:write'] },
      { label: 'Roles', path: '/auth/roles', requires: ['0.auth.permission'] },
      { label: 'Permissions', path: '/auth/permissions', requires: ['0.auth.permission'] },
    ]
  },
  {
    icon: 'fa-light fa-fw fa-microchip', label: 'Manage', menu: [
      { label: 'Customer', path: '/customers', requires: ['0.manage.customer'] },
      { label: 'Game', path: '/game/platform/accounts', requires: ['0.manage.game'] },
      { label: 'Payment', path: '/payment/platform/accounts', requires: ['0.manage.payment'] },
      { label: 'Bonus', path: '/bonus', requires: ['0.manage.bonus'] },
      { label: 'Rebate', path: '/rebate', requires: ['0.manage.rebate'] },
      { label: 'User', path: '/users', requires: ['0.manage', '0.auth.user:write'] },
      { label: 'Company', path: '/companies', requires: ['0.manage.company'] },
    ]
  },
  {
    icon: 'fa-light fa-fw fa-ballot-check', label: 'Operation', menu: [
      { label: 'Transaction', path: '/transactions', requires: ['0.operation.transaction'] },
      { label: 'Game Data Entry', path: '/entry/game-data', requires: ['0.operation.entry'] },
      { label: 'Follow Up', path: '/follow', query: { following: 1 }, requires: ['0.operation.follow-up'] },
      { label: 'Duty Roster', path: '/roster', requires: ['0.operation.roster'] },
      { label: 'Unclaim Deposit', path: '/unclaim', requires: ['0.operation.unclaim'] },
    ]
  },
  {
    icon: 'fa-light fa-fw fa-file-chart-column', label: 'Reports', menu: [
      { label: 'Daily Report', path: '/report/daily', requires: ['0.report.daily'] },
      { label: 'Customer Report', path: '/report/customer', requires: ['0.report.customer'] },
      { label: 'Sales Report', path: '/report/sales', requires: ['0.report.sales'] },
      { label: 'Game Report', path: '/report/game', requires: ['0.report.game'] },
      { label: 'Bank Report', path: '/report/bank', requires: ['0.report.bank'] },
      // { label: 'Customer Service Report', path: '/report/customer-service', requires: ['0.report.csr'] },
      { label: 'Bonus Report', path: '/report/bonus', requires: ['0.report.bonus'] },
      { label: 'Rebate Report', path: '/report/rebate', requires: ['0.report.rebate'] },
      { label: 'Commission Report', path: '/report/commission', requires: ['0.report.commission'] },
      // { label: 'Handover Report', path: '/report/handover', requires: ['0.report.handover'] },
    ]
  },
  {
    icon: 'fa-fw fa-light fa-shoe-prints', label: 'Logs', menu: [
      { label: 'Office', path: '/logs/office', requires: ['0.log.office'] },
      { label: 'Transaction', path: '/logs/transaction', requires: ['0.log.transaction'] },
    ]
  },
];
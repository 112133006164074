<form class="space-y-4 md:space-y-6" [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
  <div>
    <label for="password" class="mb-2 block">Current Password</label>
    <input type="password" name="password" id="password" placeholder="" formControlName="currentPassword" required>
  </div>
  <div>
    <label for="new-password" class="mb-2 block">New Password</label>
    <input type="password" name="new-password" id="new-password" placeholder="" formControlName="newPassword" required>
  </div>
  <button type="submit" class="button is-primary">Submit</button>
</form>

<message></message>
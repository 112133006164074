import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { Modal } from 'flowbite';
import { IModal } from './modal.interface';
import type { ModalOptions, ModalInterface } from 'flowbite'

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: [],
  standalone: true,
})
export default class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('elModal', { static: true }) elModal!: ElementRef;
  @ViewChild('vcr', { static: true, read: ViewContainerRef }) vcr!: ViewContainerRef;

  title: string = 'Modal Lab 🧪';
  modal!: ModalInterface;
  instance!: IModal;

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.initModal();
  }

  ngOnDestroy(): void { }

  initModal() {
    const $el: HTMLElement = this.elModal.nativeElement; // document.getElementById('default-modal') as HTMLElement;
    const options: ModalOptions = {
      // placement: 'bottom-right',
      // backdrop: 'dynamic',
      // backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      // closable: true,
      onHide: () => { this.afterHide() },
      onShow: () => { },
      onToggle: () => { }
    }
    this.modal = new Modal($el, options);
  }

  show(title: string, componentClass: { new(): Component }, params: any = null, callbacks: any = {}) {
    this.title = title;
    callbacks["close"] = () => { this.modal.hide(); };
    this.vcr.clear();
    this.instance = this.vcr.createComponent(componentClass).instance as IModal;
    this.instance.modalOnInit(params, callbacks);
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  afterHide() {
    this.vcr.clear();
  }
}

export { ModalComponent };
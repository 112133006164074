import _ from 'lodash-es';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { IModal } from '@components/common/modal/modal.interface';
import { MessageComponent } from '@components/common/message/message.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MessageComponent]
})
export default class ChangePasswordComponent implements OnInit, IModal {
  @ViewChild(MessageComponent) toast !: MessageComponent;

  callbacks: any;
  changePasswordForm = new FormGroup({
    currentPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required]),
  });

  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit() { }

  modalOnInit(parameters: any, callbacks: any): void {
    this.callbacks = callbacks;
  }

  onChangePassword() {
    const user = localStorage.getItem('user');
    const { currentPassword, newPassword } = this.changePasswordForm.value;

    if (!user || !currentPassword || !newPassword) {
      return this.toast.show({ title: 'Change password failed', body: 'Please provide both current and new password', icon: 'error' });
    }

    const userId = JSON.parse(user).user_id;
    return this.authService.changePassword(userId, currentPassword, newPassword).subscribe({
      next: res => this.handleOnChangePassword(res),
      error: err => this.handleOnChangePasswordError(err)
    });
  }

  handleOnChangePassword(response: any) {
    if (response.message === 'success') {
      this.callbacks.onChanged();
    }
  }

  handleOnChangePasswordError(error: HttpErrorResponse) {
    this.toast.show({ title: 'Change password failed', body: error.error.error, icon: 'error' });
  }
}

export { ChangePasswordComponent };

import { Directive, Input, TemplateRef, ViewContainerRef, Renderer2, inject } from '@angular/core';
import AuthHelper from '@helpers/auth';

@Directive({
  selector: '[authorize]',
  standalone: true
})
export class AuthorizeDirective {
  @Input() set authorize(permissions: String[]) {
    this.viewContainerRef.clear();
    const authorized = this.helper.verify(permissions);
    // console.log(`🌵 directive.authorize`, { permissions, authorized });
    if (authorized) {
      this.viewContainerRef.createEmbeddedView(
        this.templateRef
      ).rootNodes[0];
    }
  }

  helper: AuthHelper = inject(AuthHelper);

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {

  }
}
import _ from 'lodash-es';
import { Injectable, inject } from '@angular/core';
import { AuthService } from "@services/auth.service";

@Injectable({ providedIn: 'root' })
export default class AuthHelper {
  authService: AuthService = inject(AuthService);
  constructor() { }

  verify(requires: String[]): Boolean {
    const { role_extend, role_revoke, user_extend, user_revoke } = this.authService.permissions;

    for (const require of requires) {
      const [permission, write] = this.split(require);
      const access = this.check(permission, role_extend, role_revoke, user_extend, user_revoke);
      if (access === 'none') { return false; }
      if (write && access !== 'write') { return false; }
    }

    return true;
  }

  preview(permissions: String[], role_extend: String[], role_revoke: String[], user_extend: String[], user_revoke: String[]): String[][] {
    const result = [];
    for (const permission of permissions) {
      const access = this.check(permission, role_extend, role_revoke, user_extend, user_revoke);
      result.push([permission, access]);
    }
    // console.log(`🌵 preview`, { permissions, role_extend, role_revoke, user_extend, user_revoke, result });
    return result;
  }

  private check(permission: String, role_extend: String[], role_revoke: String[], user_extend: String[], user_revoke: String[]): String {
    let access = 'none';
    permission = permission.toLowerCase().replace(/ /g, '-');
    role_extend.sort((a, b) => b.split('.').length - a.split('.').length);
    user_extend.sort((a, b) => b.split('.').length - a.split('.').length);

    // + role_extend
    for (const e of role_extend) {
      const [extend, extend_write] = this.split(e);
      if (`${permission}.`.startsWith(`${extend}.`)) {
        access = extend_write ? 'write' : 'read';
        break;
      }
    }

    // - role_revoke - user_revoke
    for (const r of ([] as String[]).concat(role_revoke, user_revoke)) {
      const [revoke,] = this.split(r);
      if (`${permission}.`.startsWith(`${revoke}.`)) {
        access = 'none';
        break;
      }
    }

    // + user_extend
    for (const e of user_extend) {
      const [extend, extend_write] = this.split(e);
      if (`${permission}.`.startsWith(`${extend}.`)) {
        access = extend_write ? 'write' : 'read';
        break;
      }
    }

    return access;
  }

  private split(permission: String): [String, Boolean] {
    const [require, require_write] = permission.split(':');
    return [require, require_write === 'write'];
  }
}

export { AuthHelper };

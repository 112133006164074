import _ from 'lodash-es';
import forge from 'node-forge';
import { BehaviorSubject, catchError, tap, map, of, switchMap, combineLatest, fromEvent, skip, firstValueFrom, concat, Subscription, Subject, filter } from 'rxjs';
import { initFlowbite, Drawer } from 'flowbite';
import { CommonModule } from '@angular/common';
import { Router, RouterLinkActive, RouterLink, RouterOutlet, NavigationEnd } from '@angular/router';
import { Component, OnInit, inject, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import ApiService from '@services/api/main.service';
import { ModalService } from '@components/common/modal/modal.service';
import { ModalComponent } from '@components/common/modal/modal.component';
import { MessageComponent } from '@components/common/message/message.component';
import { ChangePasswordComponent } from '@modules/auth/components/change-password/change-password.component';
import { SettingComponent } from '@modules/auth/components/setting/setting.component';
import { FullscreenSpinnerComponent } from '@components/common/fullscreen-spinner/fullscreen-spinner.component';
import { AuthorizeDirective } from '@directives/authorize.directive';
import menu from '@app/menu';
import AuthHelper from '@helpers/auth';

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AuthorizeDirective,
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
    MessageComponent,
    ModalComponent,
    FullscreenSpinnerComponent,
    FormsModule
  ],
})
export default class StandardLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(MessageComponent, { static: true }) message !: any;
  @ViewChild(ModalComponent, { static: true }) modal !: any;
  @ViewChild(FullscreenSpinnerComponent, { static: true }) fullscreenSpinner !: any;
  @ViewChild('ddCompany', { static: true }) ddCompany: ElementRef | undefined;

  public authHelper: AuthHelper = inject(AuthHelper);

  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  apiService: ApiService = inject(ApiService);
  modalService: ModalService = inject(ModalService);
  isNavHidden = false;
  subscriptions = new Subscription();
  menu = menu;
  drawer: Drawer | undefined;
  companies: any[] = [];

  on: any = {
    company_id: null,
  }

  ob$: any = {}

  constructor() {
    this.on.company_id = localStorage.getItem('selected_company_id') ?? '';
    localStorage.setItem('selected_company_id', this.on.company_id);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(this.checkDrawer.bind(this));
  }

  ngOnInit() {
    initFlowbite();
    this.initDrawer();
    this.fetchOnInit();

    this.setupGlobalCompany();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onRouterOutletActive(component: any) { }

  async fetchOnInit() {
    try {
      const companies = await firstValueFrom(this.apiService.call('company:search', { qs: { enable: 1, sort: 'name', page_size: 0 } }));
      this.companies = companies.data;
      if(this.companies.length === 1) {
        this.on.company_id = `${this.companies[0].company_id}`;
        this.ob$.company.next(this.on.company_id);
      }
    } catch (error: any) {
      this.message.show({
        title: `Error During First Fetch`,
        body: `${error.message}\n\nPlease try again or contact support if the problem persists.`,
        icon: 'error'
      });
    }
  }

  async setupGlobalCompany() {
    this.ob$.company = new BehaviorSubject(this.on.company_id === '' ? null : +this.on.company_id);
    fromEvent(this.ddCompany?.nativeElement, 'change')
      .pipe(map((event: any) => event.target.value))
      .subscribe((company_id: string) => {
        this.on.company_id = company_id;
        this.ob$.company.next(company_id === '' ? null : +company_id);
        localStorage.setItem('selected_company_id', company_id);
      });
  }

  get username() {
    const user = localStorage.getItem('user');
    if (!user) { return 'username'; }
    return JSON.parse(user).name;
  }

  hasSubmenu(item: any) {
    return item.menu && Array.isArray(item.menu);
  }

  initDrawer() {
    const id = 'logo-sidebar';
    const $el = document.getElementById(id);
    // options with default values
    const options = {
      placement: 'left',
      backdrop: false,
      bodyScrolling: true,
      edge: false,
      edgeOffset: '',
      backdropClasses: '', // 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
      onHide: () => { },
      onShow: () => { },
      onToggle: () => { },
    };

    // instance options object
    const instanceOptions = { id };
    this.drawer = new Drawer($el, options, instanceOptions);
    if (!this.isMobile()) {
      this.drawer.show();
    }
  }

  checkDrawer() {
    if (this.isMobile()) {
      this.drawer?.hide();
    }
  }

  onToggleNav() {
    if (this.drawer?.isVisible()) {
      this.drawer?.hide();
    } else {
      this.drawer?.show();
    }
  }

  async onSignOut() {
    const user = localStorage.getItem("user");
    if (!user) { return; }

    await firstValueFrom(this.authService.signOut());

    const md5 = forge.md.md5.create();
    md5.update(`${JSON.parse(user)?.user_id}`);
    this.router.navigate(['/signin'], { queryParams: { return: this.router.url, id: md5.digest().toHex() } });
  }

  onChangePassword() {
    const callbacks = {
      onChanged: () => {
        this.modal.close();
        this.message.show({
          title: 'Change password successfully',
          body: '',
          icon: 'check'
        });
      },
    }

    this.modal.show('Change Password', ChangePasswordComponent, {}, callbacks);
  }

  onChangeTimeZone() {
    const callbacks = {
      onChanged: () => {
        this.modal.close();
        this.message.show({
          title: 'Setting changed successfully',
          body: '',
          icon: 'check'
        });
      },
    }

    this.modal.show('Setting', SettingComponent, {}, callbacks);
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

}

export { StandardLayoutComponent };
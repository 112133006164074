import _ from 'lodash-es';
import { Component, OnInit, OnDestroy } from '@angular/core';
import MessageService from './message.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: [],
  standalone: true,
  imports: [NgIf],
})
export default class MessageComponent implements OnInit, OnDestroy {
  onShow = false;
  message: any = {
    title: '•',
    body: '•',
    icon: null,
  };

  constructor(private messageService: MessageService) { }

  ngOnInit() {
    this.messageService.message$.subscribe(this.onMessage.bind(this));
  }

  ngOnDestroy(): void { }

  show(message: any, callback = () => { }) {
    this.message.title = message.title;
    this.message.body = message.body;
    this.message.icon = message.icon ?? null;
    this.onShow = true;

    callback();
  }

  hide() {
    this.onShow = false;
  }

  toggle() {
    this.onShow = !this.onShow;
  }

  onMessage(message: any) {
    if (!message) {
      this.hide();
    } else {
      this.show(message);
    }
  }
}


export { MessageComponent };
// import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from "@angular/core";
import { AuthService } from "@services/auth.service";
import forge from 'node-forge';

/**
 * Ensure user is authenticated for route
 * 
 * @returns 
 */
export default function (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  const router = inject(Router);
  const authService = inject(AuthService);
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  // if (authService.isSignedIn) {
  //   return true;
  // }
  if (token) {
    return true;
  }
  const url = [`/signin`];
  if (user) {
    const md5 = forge.md.md5.create();
    md5.update(`${JSON.parse(user || "")?.user_id}`);
    const id = md5.digest().toHex();
    url.push(`?return=${state.url}&id=${id}`);
  }
  return router.parseUrl(url.join(""));
};
<form class="space-y-4 md:space-y-6" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div>
    <label for="timezone">Time Zone</label>
    <select id="timezone" formControlName="timezone">
      <optgroup label="Official Time Zone">
        <option value="Asia/Singapore">Asia/Singapore </option>
      </optgroup>
      <optgroup label="More Time Zone">
        <option value="Asia/Manila">Asia/Manila</option>
        <option value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur</option>
        <option value="Asia/Bangkok">Asia/Bangkok</option>
        <option value="Asia/Jakarta">Asia/Jakarta</option>
        <option value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</option>
        <option value="Asia/Yangon">Asia/Yangon</option>
        <option value="Australia/Perth">Australia/Perth</option>
        <option value="Australia/Melbourne">Australia/Melbourne</option>
      </optgroup>
    </select>
  </div>
  <button type="submit" class="button is-primary">Submit</button>
</form>

<message></message>
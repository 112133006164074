import { Injectable, inject } from '@angular/core';
import { HttpService } from '@services/http.service';
import endpoints from './endpoints';

@Injectable({ providedIn: 'root' })
export default class Service extends HttpService {

  constructor() { super(); }

  call(key: string, params: any = {}) {
    try {
      const [method, _path] = endpoints[key] ?? [];
      const { identifiers, qs, body } = params;
      const path = _path.replace(/:([0-9a-z_]+)/g, (match, p1) => identifiers[p1]);
      switch (method) {
        case 'GET': return this.get(path, qs);
        case 'POST': return this.post(path, qs, body);
        case 'PUT': return this.put(path, qs, body);
        case 'DELETE': return this.delete(path, qs, body);
      }
    } catch (error) {
      throw new Error(`Error during API call: ${key}`);
    }
  }
}
import { BehaviorSubject } from 'rxjs';
import { Component, Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export default class ModalService {
  public currentComponent!: Component;
  public component$: BehaviorSubject<Component> = new BehaviorSubject<Component>(this.currentComponent);
  public show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  show(component: any) {
    this.component$.next(component);
    this.show$.next(true);
  }

  hide() {
    this.show$.next(false);
  }
}

export { ModalService };
